import './bootstrap';
import '../css/app.css';

import {hydrateRoot} from 'react-dom/client'
import {createInertiaApp, router} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";
import {AnalyticsEvent, sendEvent} from "@/services/analytics";
import axios from "axios";
import {config} from "@fortawesome/fontawesome-svg-core";
import {ConfirmProvider} from "@/Components/ConfirmDialog";
import toast from "react-hot-toast";

// css is already imported in app.css
config.autoAddCss = false;

const appName = import.meta.env.VITE_APP_NAME || 'Subtitle Extractor';
(window as any).global = window;

if (process.env.NODE_ENV === "production") {
    initSentry();
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({el, App, props}) {
        hydrateRoot(el,
        <ConfirmProvider>
            <App {...props} />
        </ConfirmProvider>)
    },
}).then(() => {
    axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response || {};
            if (authIsExpired(status)) {
                console.error("Auth expired, reloading page")
                window.location.reload();
                return new Promise(() => {});
            } else {
                return Promise.reject(error);
            }
        }
    );

    router.on('exception', (event) => {
        const { exception } = event.detail;
        if (exception.message === "Network Error") {
            event.preventDefault();
            toast.error("Request failed. Please check your connection and try again.");
        }
    });
});

function authIsExpired(errorCode: number) {
    return errorCode === 401 || errorCode === 419;
}

if (process.env.NODE_ENV === "production") {
    initAnalytics();
}

function initAnalytics() {
    gtag("js", new Date());
    gtag("config", "G-EBCTD36W1G", {send_page_view: false});
    gtag('config', 'AW-10849655585');

    router.on("navigate", (event) => {
        setTimeout(() => {
            sendEvent(AnalyticsEvent.PAGE_VIEW, {
                page_location: event.detail.page.url,
                page_title: document.title,
            })
        }, 250);  // timeout ensures that the title is updated
    })
}

function initSentry() {
    Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        enabled: process.env.NODE_ENV === 'production',
        ignoreErrors: [
            "ResizeObserver loop limit exceeded"
        ],
        beforeSend(event, hint) {
            if (
                event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
                    f.filename?.includes(`googletagmanager.com/gtag`))
            ) {
                return null;
            }
            return event;
        },
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}
